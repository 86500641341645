import React from 'react';
import PropType from 'prop-types';

const Progression = ({ progression }) => (
  <div className="progression-circle-wrap">
    <div className="circle" style={{ '--progression': `${progression}%` }}>
      <div className="inner-circle">{progression}%</div>
    </div>
  </div>
);

Progression.propTypes = {
  progression: PropType.number.isRequired,
};

export default Progression;
