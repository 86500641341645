import React, { useContext } from 'react';
import { ensureConfig } from '@edx/frontend-platform';
import { AppContext } from '@edx/frontend-platform/react';
import {
  injectIntl,
  FormattedMessage,
  intlShape,
} from '@edx/frontend-platform/i18n';
import {
  Container,
  Image,
  MailtoLink,
  Row,
  Col,
  Hyperlink,
} from '@edx/paragon';
import fbLogo from './img/logo_facebook.svg';
import linkedinLogo from './img/logo_linkedin.svg';
import twitterLogo from './img/logo_twitter.svg';

import messages from './footer.messages';

ensureConfig([
  'LMS_BASE_URL',
  'MY_PROFILE_URL',
  'MY_COURSES_URL',
  'MQ_WEBSITE_NEWS_URL',
  'MQ_WEBSITE_MEDICAL_URL',
  'MQ_WEBSITE_TOPICS_URL',
  'MQ_WEBSITE_CONGRESSES_URL',
  'MQ_WEBSITE_CGU',
  'MQ_WEBSITE_PRIVACY_POLICY',
], 'Footer component');

function Footer({ intl }) {
  const { config } = useContext(AppContext);

  return (
    <Container className="footer_block">
      <Row className="footer_div justify-content-between">
        <Col className="footer_div_mediquality" sm={7}>
          <h3 className="footer_title">
            <FormattedMessage
              id="footer.title.about-mq"
              defaultMessage="About MediQuality"
              description="MQ title in footer"
            />
          </h3>
          <p>
            <FormattedMessage
              id="footer.text.about-mq"
              defaultMessage="MediQuality provides various services to its community, including daily professional information, more than 30 scientific newsletters, congress coverage, e-learning, events agenda, and so on. HealthCare Professionals are active on MediQuality through participation to opinion polls, regular personal contributions by leading opinion makers and numerous daily reader posts."
              description="paragraph under title 'About MediQuality' in footer"
            />
          </p>
          <Container className="footer_div_info">
            <Col className="footer_div_contact" sm={9}>
              <Row>
                <MailtoLink
                  to="info@mediquality.net"
                  className="footer_link_contact"
                >
                  info@mediquality.net
                </MailtoLink>
              </Row>
              <Row>
                <Hyperlink
                  destination="tel:+32010395450"
                  className="footer_link_contact"
                >
                  010/39 54 50
                </Hyperlink>
              </Row>
              <Row>
                <p className="footer_text">
                  Rue Emile Francqui, 5<br />
                  1435 Mont-Saint-Guibert
                  <br />
                  Belgique
                </p>
              </Row>
            </Col>
            <Col className="footer_div_nav">
              <Row>
                <Hyperlink
                  destination={config.MQ_WEBSITE_NEWS_URL}
                  className="footer_link_nav"
                  target="_blank"
                  showLaunchIcon={false}
                >
                  {intl.formatMessage(messages['footer.link.news'])}
                </Hyperlink>
              </Row>
              <Row>
                <Hyperlink
                  destination={config.MQ_WEBSITE_MEDICAL_URL}
                  className="footer_link_nav"
                  target="_blank"
                  showLaunchIcon={false}
                >
                  {intl.formatMessage(messages['footer.link.medical'])}
                </Hyperlink>
              </Row>
              <Row>
                <Hyperlink
                  destination={config.MQ_WEBSITE_TOPICS_URL}
                  className="footer_link_nav"
                  target="_blank"
                  showLaunchIcon={false}
                >
                  {intl.formatMessage(messages['footer.link.topics'])}
                </Hyperlink>
              </Row>
              <Row>
                <Hyperlink
                  destination={config.MQ_WEBSITE_CONGRESSES_URL}
                  className="footer_link_nav"
                  target="_blank"
                  showLaunchIcon={false}
                >
                  {intl.formatMessage(messages['footer.link.congresses'])}
                </Hyperlink>
              </Row>
            </Col>
          </Container>
        </Col>
        <Col className="footer_div_academy" sm={4}>
          <Container className="footer_div_nav">
            <h3 className="footer_title">
              <FormattedMessage
                id="footer.title.academy"
                defaultMessage="Academy"
                description="Academy title in footer"
              />
            </h3>
            <Row className="footer_list_horizontal">
              <Col sm={3}>
                <Hyperlink
                  destination={config.LMS_BASE_URL}
                  className="footer_link_nav"
                  showLaunchIcon={false}
                >
                  {intl.formatMessage(messages['footer.link.home'])}
                </Hyperlink>
              </Col>
              <Col sm={3}>
                <Hyperlink
                  destination={config.MY_PROFILE_URL}
                  className="footer_link_nav"
                  showLaunchIcon={false}
                >
                  {intl.formatMessage(messages['footer.link.profile'])}
                </Hyperlink>
              </Col>
              <Col sm={6}>
                <Hyperlink
                  destination={config.MY_COURSES_URL}
                  className="footer_link_nav"
                  showLaunchIcon={false}
                >
                  {intl.formatMessage(messages['footer.link.courses'])}
                </Hyperlink>
              </Col>
            </Row>
          </Container>
          <Container className="footer_div_social">
            <h3 className="footer_title">
              <FormattedMessage
                id="footer.title.social"
                defaultMessage="Follow us on"
                description="Social title in footer"
              />
            </h3>
            <Row className="footer_list_horizontal">
              <Col>
                <Hyperlink
                  destination="https://www.facebook.com/MediQuality/"
                  target="_blank"
                  showLaunchIcon={false}
                >
                  <Image src={fbLogo} alt="Facebook" />
                </Hyperlink>
              </Col>
              <Col>
                <Hyperlink
                  destination="https://www.linkedin.com/company/mediquality/"
                  target="_blank"
                  showLaunchIcon={false}
                >
                  <Image src={linkedinLogo} alt="LinkedIn" />
                </Hyperlink>
              </Col>
              <Col>
                <Hyperlink
                  destination="https://twitter.com/MediQuality"
                  target="_blank"
                  showLaunchIcon={false}
                >
                  <Image src={twitterLogo} alt="Twitter" />
                </Hyperlink>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Container className="footer_div_footer">
        <hr />
        <Row className="footer_list_horizontal">
          <div>
            <p className="footer_link_nav small">© {new Date().getFullYear()} MediQuality</p>
          </div>
          <div>
            <Hyperlink
              destination={config.MQ_WEBSITE_CGU}
              target="_blank"
              className="footer_link_nav small"
              showLaunchIcon={false}
            >
              {intl.formatMessage(messages['footer.link.cgu'])}
            </Hyperlink>
          </div>
          <div>
            <Hyperlink
              destination={config.MQ_WEBSITE_PRIVACY_POLICY}
              target="_blank"
              className="footer_link_nav small"
              showLaunchIcon={false}
            >
              {intl.formatMessage(messages['footer.link.policy'])}
            </Hyperlink>
          </div>
        </Row>
      </Container>
    </Container>
  );
}
Footer.propTypes = {
  intl: intlShape.isRequired,
};
export default injectIntl(Footer);
