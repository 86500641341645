import React, { useContext } from 'react';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { AppContext } from '@edx/frontend-platform/react';
import {
  APP_CONFIG_INITIALIZED,
  ensureConfig,
  mergeConfig,
  getConfig,
  subscribe,
} from '@edx/frontend-platform';

import DesktopHeader from './DesktopHeader';
// import MobileHeader from './MobileHeader';

import messages from './Header.messages';

import './index.scss';

subscribe(APP_CONFIG_INITIALIZED, () => {
  mergeConfig({
    AUTHN_MINIMAL_HEADER: !!process.env.AUTHN_MINIMAL_HEADER,
  }, 'Header additional config');
});

ensureConfig([
  'LMS_BASE_URL',
  'LOGOUT_URL',
  'LOGIN_URL',
  'SITE_NAME',
  'LOGO_URL',
  'STUDIO_BASE_URL',
  'MY_COURSES_URL',
  'MY_PROFILE_URL',
  'LOGO_SEARCH_URL',
], 'Header component');

const Header = ({ intl }) => {
  const { authenticatedUser, config } = useContext(AppContext);

  const mainMenu = [
    {
      type: 'item',
      href: `${config.LMS_BASE_URL}/dashboard`,
      content: intl.formatMessage(messages['header.links.courses']),
    },
  ];

  const languagesItems = [
    {
      type: 'item',
      locale: 'fr',
      content: intl.formatMessage(messages['header.user.language.fr']),
    },
    {
      type: 'item',
      locale: 'nl',
      content: intl.formatMessage(messages['header.user.language.nl']),
    },
  ];

  function userMenu() {
    if (authenticatedUser === null) {
      return [];
    }

    const menu = [
      {
        type: 'item',
        href: `${config.MY_PROFILE_URL}`,
        content: intl.formatMessage(messages['header.user.menu.account.settings']),
      },
      {
        type: 'item',
        href: `${config.MY_COURSES_URL}`,
        content: intl.formatMessage(messages['header.user.menu.dashboard']),
      },
      {
        type: 'button',
        href: config.LOGOUT_URL,
        content: intl.formatMessage(messages['header.user.menu.logout']),
        style: 'btn btn-light',
      },
    ];

    if (authenticatedUser.administrator === true) {
      const adminItems = [
        {
          type: 'button',
          href: `${config.STUDIO_BASE_URL}`,
          content: intl.formatMessage(messages['header.user.menu.addcourse']),
          style: 'btn btn-primary',
        },
      ];
      menu.splice(-1, 0, ...adminItems);
    }

    return menu;
  }

  const loggedOutItems = [
    {
      type: 'item',
      href: `${config.LMS_BASE_URL}/register`,
      content: intl.formatMessage(messages['header.user.menu.register']),
    },
    {
      type: 'item',
      href: config.LOGIN_URL,
      content: intl.formatMessage(messages['header.user.menu.login']),
    },
  ];

  const props = {
    logo: config.LOGO_URL,
    logoAltText: config.SITE_NAME,
    logoDestination: `${config.LMS_BASE_URL}/dashboard`,
    loggedIn: authenticatedUser !== null,
    username: authenticatedUser !== null ? authenticatedUser.username : null,
    avatar: authenticatedUser !== null ? authenticatedUser.avatar : null,
    mainMenu: getConfig().AUTHN_MINIMAL_HEADER ? [] : mainMenu,
    userMenu: getConfig().AUTHN_MINIMAL_HEADER ? [] : userMenu(),
    languagesItems,
    loggedOutItems: getConfig().AUTHN_MINIMAL_HEADER ? [] : loggedOutItems,
  };

  return (
    <>
      {/* <Responsive maxWidth={768}> */}
      {/* <MobileHeader {...props} /> */}
      {/* </Responsive> */}
      {/* <Responsive minWidth={769}> */}
      <DesktopHeader {...props} />
      {/* </Responsive> */}
    </>
  );
};

Header.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Header);
