// no need to import en messages-- they are in the defaultMessage field
import nlMessages from './messages/nl.json';
import frMessages from './messages/fr.json';

const messages = {
  fr: frMessages,
  nl: nlMessages,
};

export default messages;
