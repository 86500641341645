import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  'header.links.courses': {
    id: 'header.links.courses',
    defaultMessage: 'Courses',
    description: 'Link to the learner course dashboard',
  },
  'header.links.programs': {
    id: 'header.links.programs',
    defaultMessage: 'Programs',
    description: 'Link to the learner program dashboard',
  },
  'header.links.content.search': {
    id: 'header.links.content.search',
    defaultMessage: 'Discover New',
    description: 'Link to the content search page',
  },
  'header.links.schools': {
    id: 'header.links.schools',
    defaultMessage: 'Schools & Partners',
    description: 'Link to the schools and partners landing page',
  },
  'header.user.menu.dashboard': {
    id: 'header.user.menu.dashboard',
    defaultMessage: 'My training',
    description: 'Link to the user dashboard',
  },
  'header.user.menu.account.settings': {
    id: 'header.user.menu.account.settings',
    defaultMessage: 'Account',
    description: 'Link to account settings',
  },
  'header.user.menu.logout': {
    id: 'header.user.menu.logout',
    defaultMessage: 'Logout',
    description: 'Logout link',
  },
  'header.user.menu.login': {
    id: 'header.user.menu.login',
    defaultMessage: 'Login',
    description: 'Login link',
  },
  'header.user.menu.register': {
    id: 'header.user.menu.register',
    defaultMessage: 'Sign Up',
    description: 'Link to registration',
  },
  'header.user.menu.studio.home': {
    id: 'header.user.menu.studio.home',
    defaultMessage: 'Studio Home',
    description: 'Link to the Studio Home',
  },
  'header.user.menu.studio.maintenance': {
    id: 'header.user.menu.studio.maintenance',
    defaultMessage: 'Maintenance',
    description: 'Link to the Studio Maintenance',
  },
  'header.label.account.nav': {
    id: 'header.label.account.nav',
    defaultMessage: 'Account',
    description: 'The aria label for the account menu nav',
  },
  'header.label.account.menu': {
    id: 'header.label.account.menu',
    defaultMessage: 'Account Menu',
    description: 'The aria label for the account menu trigger',
  },
  'header.label.account.menu.for': {
    id: 'header.label.account.menu.for',
    defaultMessage: 'Account menu for {username}',
    description: 'The aria label for the account menu trigger when the username is displayed in it',
  },
  'header.label.main.nav': {
    id: 'header.label.main.nav',
    defaultMessage: 'Main',
    description: 'The aria label for the main menu nav',
  },
  'header.label.main.menu': {
    id: 'header.label.main.menu',
    defaultMessage: 'Main Menu',
    description: 'The aria label for the main menu trigger',
  },
  'header.label.main.header': {
    id: 'header.label.main.header',
    defaultMessage: 'Main',
    description: 'The aria label for the main header',
  },
  'header.label.secondary.nav': {
    id: 'header.label.secondary.nav',
    defaultMessage: 'Secondary',
    description: 'The aria label for the seconary nav',
  },
  'header.label.skip.nav': {
    id: 'header.label.skip.nav',
    defaultMessage: 'Skip to main content',
    description: 'A link used by screen readers to allow users to skip to the main content of the page.',
  },
  'header.label.app.nav': {
    id: 'header.label.app.nav',
    defaultMessage: 'App',
    description: 'The aria label for the app Nav',
  },
  'header.menu.search.label': {
    id: 'header.menu.search.label',
    defaultMessage: 'Search',
    description: 'The label for the user menu Search action.',
  },
  'header.user.language.fr': {
    id: 'header.user.language.fr',
    defaultMessage: 'Fr',
    description: 'French Language.',
  },
  'header.user.language.nl': {
    id: 'header.user.language.nl',
    defaultMessage: 'Nl',
    description: 'Dutch Language.',
  },
  'header.user.menu.author': {
    id: 'header.user.menu.author',
    defaultMessage: 'My author page',
    description: 'author page action',
  },
  'header.user.menu.addcourse': {
    id: 'header.user.menu.addcourse',
    defaultMessage: '+ Add New Course',
    description: 'author new course action',
  },
});

export default messages;
