import { defineMessages } from '@edx/frontend-platform/i18n';

export default defineMessages(
  {
    'course-card.accredited-points': {
      id: 'course-card.accredited-points',
      defaultMessage: 'Accredited: {points, plural, =0 {Not} one {1 point} other {{points} points}}',
    },
    'course-card.accredited-by': {
      id: 'course-card.accredited-by',
      defaultMessage: ' by {organism}',
    },
    'course-card.accredited-code': {
      id: 'course-card.accredited-code',
      defaultMessage: ' (code : {code})',
    },
    'course-card.organized-by': {
      id: 'course-card.organized-by',
      defaultMessage: 'Organized by',
    },
  },
);
