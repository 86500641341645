import React, { useState } from 'react';
import PropType from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import showMoreImg from '../../assets/images/icon_show_more.png';
import messages from './AdminMenu.messages';

const AdminMenu = ({
  id, onDeleteClicked, onDraftClicked, onEditClicked, intl,
}) => {
  const [contextMenuOpenened, setContextMenuOpened] = useState(false);

  const closeMenu = () => {
    setContextMenuOpened(false);
  };

  const openMenu = () => {
    setContextMenuOpened(true);
  };

  const clickOnDelete = () => {
    closeMenu();
    onDeleteClicked({ courseId: id });
  };

  const clickOnDraft = () => {
    closeMenu();
    onDraftClicked({ courseId: id });
  };

  const clickOnEdit = () => {
    closeMenu();
    onEditClicked({ courseId: id });
  };

  const ContextMenu = () => (
    <>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div className="close-overlay" onClick={() => closeMenu()} onKeyUp={() => closeMenu()} role="button" tabIndex={0} />
      <div className="context-menu">
        <button type="button" onClick={() => clickOnEdit()} onKeyUp={() => clickOnEdit()}>{intl.formatMessage(messages['edit-course'])}</button>
        <button type="button" onClick={() => clickOnDraft()} onKeyUp={() => clickOnDraft()}>{intl.formatMessage(messages['draft-course'])}</button>
        <button type="button" onClick={() => clickOnDelete()} onKeyUp={() => clickOnDelete()}>{intl.formatMessage(messages['delete-course'])}</button>
      </div>
    </>
  );

  return (
    <div className="admin-menu">
      <div className="trigger-context-menu" onClick={() => openMenu()} onKeyUp={() => openMenu()} role="button" tabIndex={0}>
        <img src={showMoreImg} alt={intl.formatMessage(messages['show-more-img-alt'])} />
      </div>
      {contextMenuOpenened && <ContextMenu />}
    </div>
  );
};

AdminMenu.propTypes = {
  id: PropType.string.isRequired,
  onDeleteClicked: PropType.func.isRequired,
  onDraftClicked: PropType.func.isRequired,
  onEditClicked: PropType.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(AdminMenu);
