import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages(
  {
    'footer.link.news': {
      id: 'footer.link.news',
      defaultMessage: 'News',
      description: 'News link in footer',
    },
    'footer.link.medical': {
      id: 'footer.link.medical',
      defaultMessage: 'Medical',
      description: 'Medical link in footer',
    },
    'footer.link.topics': {
      id: 'footer.link.topics',
      defaultMessage: 'Topics',
      description: 'Topics link in footer',
    },
    'footer.link.congresses': {
      id: 'footer.link.congresses',
      defaultMessage: 'Congresses',
      description: 'Congresses link in footer',
    },
    'footer.link.home': {
      id: 'footer.link.home',
      defaultMessage: 'Home',
      description: 'Home link in footer',
    },
    'footer.link.profile': {
      id: 'footer.link.profile',
      defaultMessage: 'Profile',
      description: 'Profile link in footer',
    },
    'footer.link.courses': {
      id: 'footer.link.courses',
      defaultMessage: 'My courses',
      description: 'Courses link in footer',
    },
    'footer.link.cgu': {
      id: 'footer.link.cgu',
      defaultMessage: 'User terms and conditions',
      description: 'TOU link in footer',
    },
    'footer.link.policy': {
      id: 'footer.link.policy',
      defaultMessage: 'Privacy policy',
      description: 'Privacy policy link in footer',
    },
    'footer.text.about-mq': {
      id: 'footer.text.about-mq',
      defaultMessage: 'MediQuality provides various services to its community, including daily professional information, more than 30 scientific newsletters, congress coverage, e-learning, events agenda, and so on. HealthCare Professionals are active on MediQuality through participation to opinion polls, regular personal contributions by leading opinion makers and numerous daily reader posts.',
      description: 'About MQ',
    },
    'footer.title.about-mq': {
      id: 'footer.title.about-mq',
      defaultMessage: 'About MediQuality',
      description: 'About MQ',
    },
    footer_div_social: {
      id: 'footer_div_social',
      defaultMessage: 'Follow us on',
      description: 'Social links',
    },
  },
);

export default messages;
