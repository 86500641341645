import { defineMessages } from '@edx/frontend-platform/i18n';

export default defineMessages(
  {
    'like-img-alt': {
      id: 'like-btn.like-img-alt',
      defaultMessage: 'Like',
    },
    'liked-img-alt': {
      id: 'like-btn.liked-img-alt',
      defaultMessage: 'Liked',
    },
  },
);
