import React from 'react';
import { Card } from '@edx/paragon';
import PropType from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import classNames from 'classnames';
import messages from './CourseCard.messages';
import AdminMenu from './admin-menu/AdminMenu';
import Progression from './progression/Progression';
import LikeBtn from './like-btn/LikeBtn';

const CourseCard = ({
  id, category, image,
  accreditationPoints, accreditationOrganism, accreditationNumber,
  courseUrl, followed,
  title, approbationCode, organizer, progression,
  hidden, myCourse,
  onDeleteClicked, onDraftClicked, onEditClicked, onLikeClicked,
  intl,
}) => (
  <Card className={classNames('course-card', { hidden })}>
    <div className="category">{category}</div>
    <div className="actions">
      <div className="hiddeable">
        {progression !== undefined
          ? <Progression progression={progression} />
          : <LikeBtn followed={followed} onLikeClicked={onLikeClicked} id={id} intl={intl} />}
        <div className="hidden-overlay" />
      </div>
      {myCourse && (
        <AdminMenu
          onDeleteClicked={onDeleteClicked}
          onDraftClicked={onDraftClicked}
          onEditClicked={onEditClicked}
          id={id}
        />
      )}
    </div>
    <Card.ImageCap src={image} />
    <Card.Section>
      <div className="credits">
        {accreditationPoints > 0 ? intl.formatMessage(messages['course-card.accredited-points'], { points: accreditationPoints }) : '\u00A0' }
        {accreditationOrganism && intl.formatMessage(messages['course-card.accredited-by'], { organism: accreditationOrganism })}
        {accreditationNumber && intl.formatMessage(messages['course-card.accredited-code'], { code: accreditationNumber })}
      </div>
      <div className="title">
        <a href={courseUrl}>{title}</a>
      </div>
      <div className="organizer">
        {intl.formatMessage(messages['course-card.organized-by'])}&nbsp;<span className="organizer-name">{organizer}</span>
      </div>
    </Card.Section>
    <hr className="separator" />
    {approbationCode && (
    <Card.Footer className="footer">
      <small className="approbation-code">{approbationCode}</small>
    </Card.Footer>
    )}
    <div className="hidden-overlay" />
  </Card>
);

CourseCard.defaultProps = {
  approbationCode: undefined,
  accreditationPoints: 0,
  accreditationOrganism: undefined,
  accreditationNumber: undefined,
  category: 'e-learning',
  progression: undefined,
};

CourseCard.propTypes = {
  approbationCode: PropType.string,
  courseUrl: PropType.string.isRequired,
  accreditationPoints: PropType.number,
  accreditationOrganism: PropType.string,
  accreditationNumber: PropType.number,
  category: PropType.string,
  followed: PropType.bool.isRequired,
  id: PropType.string.isRequired,
  image: PropType.string.isRequired,
  intl: intlShape.isRequired,
  onDeleteClicked: PropType.func.isRequired,
  onDraftClicked: PropType.func.isRequired,
  onEditClicked: PropType.func.isRequired,
  onLikeClicked: PropType.func.isRequired,
  organizer: PropType.string.isRequired,
  title: PropType.string.isRequired,
  progression: PropType.number,
  hidden: PropType.bool.isRequired,
  myCourse: PropType.bool.isRequired,
};

export default injectIntl(CourseCard);
