import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { getConfig } from '@edx/frontend-platform';

import Cookies from 'universal-cookie';
// Local Components
import { Menu, MenuTrigger, MenuContent } from './Menu';
import Avatar from './Avatar';

import { LinkedLogo, Logo } from './Logo';
// i18n
import messages from './Header.messages';

// Assets
import { CaretIcon } from './Icons';

import LoginAvatar from './assets/images/login_avatar.png';
import SearchLogo from './assets/images/search_logo.png';

const cookies = new Cookies();
const config = getConfig();
const DesktopHeader = ({
  avatar,
  languagesItems,
  logo,
  logoAltText,
  logoDestination,
  loggedIn,
  loggedOutItems,
  userMenu,
  username,
  intl,
}) => {
  const [showInput, setShowInput] = useState(false);
  const languageRef = useRef(null);
  const handleClickSearch = (e) => {
    e.preventDefault();
    setShowInput(prevState => !prevState);
  };

  /* eslint-disable */
  function searchLogo() {
    return (
      <form className={showInput ? ' search__form search__form--show' : 'search__form'}>
        <LinkedLogo
          className="search__logo"
          onClick={(e) => handleClickSearch(e)}
          href={`${getConfig().LMS_BASE_URL}/search`}
          src={SearchLogo}
          alt={intl.formatMessage(messages['header.menu.search.label'])}
        />
        <input className={showInput ? 'show' : null} />
      </form>
    );
  }
  /* eslint-enable */
  function renderUserMenu() {
    return (
      <Menu transitionClassName="menu-dropdown" transitionTimeout={250}>
        <MenuTrigger
          tag="div"
          aria-label={intl.formatMessage(messages['header.label.account.menu.for'], { username })}
          className="d-inline-flex align-items-center pl-2 pr-3"
        >
          <Avatar size="55px" src={avatar} alt="" className="mr-2" />
        </MenuTrigger>
        <MenuContent className="mb-0 dropdown-menu show dropdown-menu-right pin-right shadow py-2">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="dropdown-username dropdown-item">{username}</a>
          {userMenu.map(({
            type,
            href,
            content,
            style,
          }) => (
            <a className={`dropdown-${type}`} key={`${type}-${content}`} href={href}>
              { style ? <span className={style}>{content}</span> : content }
            </a>
          ))}
        </MenuContent>
      </Menu>
    );
  }

  function renderLoggedOutItems() {
    return loggedOutItems.map((item) => (
      <a
        key={`${item.type}-${item.content}`}
        className={`${item.href.split('/').pop() === 'login' ? 'btn mr-4 btn-primary btn-login' : 'btn mr-2 btn-signup'}`}
        href={item.href}
      >
        {item.content}
        <img src={LoginAvatar} alt="" className="login_avatar mr-2" />
      </a>
    ));
  }

  function renderLanguageItems() {
    function handleClick(e, locale) {
      e.preventDefault();
      const domain = new URL(config.LMS_BASE_URL);
      cookies.set(config.LANGUAGE_PREFERENCE_COOKIE_NAME, locale, { domain: `.${domain.host}` });
      languageRef.current.close();
      window.location.reload(false);
    }

    return (
      <Menu transitionClassName="menu-dropdown" transitionTimeout={250} ref={languageRef}>
        <MenuTrigger
          tag="button"
          aria-label={intl.formatMessage(messages['header.user.language.fr'])}
          className="btn btn-secondary d-inline-flex align-items-center pl-2 pr-3"
        >
          {intl.locale.toUpperCase()} &nbsp;<CaretIcon role="img" aria-hidden focusable="false" />
        </MenuTrigger>
        <MenuContent className="mb-0 dropdown-menu show dropdown-menu-right pin-right shadow py-2 language-menu">
          {languagesItems.map(({ type, locale, content }) => (
            <a onClick={(e) => handleClick(e, locale)} className={`dropdown-${type}`} key={`${type}-${content}`} href={locale}>{content}</a>
          ))}
        </MenuContent>
      </Menu>
    );
  }

  const logoProps = { src: logo, alt: logoAltText, href: logoDestination };
  const logoClasses = getConfig().AUTHN_MINIMAL_HEADER ? 'mw-100' : null;

  return (
    <header className="site-header-desktop">
      <a className="nav-skip sr-only sr-only-focusable" href="#main">{intl.formatMessage(messages['header.label.skip.nav'])}</a>
      <div className={`container-fluid ${logoClasses}`}>
        <div className="nav-container position-relative d-flex align-items-center">
          {logoDestination === null ? <Logo className="logo" src={logo} alt={logoAltText} /> : <LinkedLogo className="logo" {...logoProps} />}
          <nav
            aria-label={intl.formatMessage(messages['header.label.secondary.nav'])}
            className="nav secondary-menu-container align-items-center ml-auto"
          >
            {loggedIn ? renderUserMenu() : renderLoggedOutItems()}
            {renderLanguageItems()}
          </nav>
        </div>
      </div>
    </header>
  );
};

DesktopHeader.propTypes = {
  mainMenu: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
  ]),
  userMenu: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['item', 'menu']),
    href: PropTypes.string,
    content: PropTypes.string,
    style: PropTypes.string,
  })),
  loggedOutItems: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['item', 'menu']),
    href: PropTypes.string,
    content: PropTypes.string,
  })),
  logo: PropTypes.string,
  logoAltText: PropTypes.string,
  logoDestination: PropTypes.string,
  avatar: PropTypes.string,
  username: PropTypes.string,
  loggedIn: PropTypes.bool,
  languagesItems: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['item']),
    href: PropTypes.string,
    content: PropTypes.string,
  })).isRequired,

  // i18n
  intl: intlShape.isRequired,

  // appMenu
  appMenu: PropTypes.shape(
    {
      content: PropTypes.string,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          href: PropTypes.string,
          content: PropTypes.string,
        }),
      ),
    },
  ),
};

DesktopHeader.defaultProps = {
  mainMenu: [],
  userMenu: [],
  loggedOutItems: [],
  logo: null,
  logoAltText: null,
  logoDestination: null,
  avatar: null,
  username: null,
  loggedIn: false,
  appMenu: null,
};

export default injectIntl(DesktopHeader);
