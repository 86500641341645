import { defineMessages } from '@edx/frontend-platform/i18n';

export default defineMessages(
  {
    'delete-course': {
      id: 'admin-menu.delete-course',
      defaultMessage: 'Delete',
    },
    'draft-course': {
      id: 'admin-menu.draft-course',
      defaultMessage: 'Draft',
    },
    'edit-course': {
      id: 'admin-menu.edit-course',
      defaultMessage: 'Edit',
    },
    'show-more-img-alt': {
      id: 'admin-menu.show-more-img-alt',
      defaultMessage: 'Show more',
    },
  },
);
