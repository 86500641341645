import React from 'react';
import PropType from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import likeImg from '../../assets/images/icon_like.png';
import likedImg from '../../assets/images/icon_liked.png';
import messages from './LikeBtn.messages';

const LikeBtn = ({
  followed, onLikeClicked, id, intl,
}) => (
  <div onClick={() => onLikeClicked({ courseId: id })} onKeyUp={() => onLikeClicked({ courseId: id })} className="like-btn" role="button" tabIndex={0}>
    {followed ? <img src={likedImg} alt={intl.formatMessage(messages['liked-img-alt'])} /> : <img src={likeImg} alt={intl.formatMessage(messages['like-img-alt'])} />}
  </div>
);

LikeBtn.propTypes = {
  followed: PropType.bool.isRequired,
  id: PropType.string.isRequired,
  onLikeClicked: PropType.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(LikeBtn);
